export default (theme) => ({
	root: {
		padding: "2px 6px 2px 16px",
		display: "flex",
		alignItems: "center",
		//maxWidth: 500,
		marginBottom: "16px",
	},
	searchBorder: {
		"border": "1px solid #DDD",
		"boxShadow": "rgba(0,0,0,.1) 0 3px 8px",
		"&:hover": {
			boxShadow: "rgba(0,0,0,.1) 0 2px 4px,rgba(26,26,29,.08) 0 5px 12px",
		},
		"&:focus": {
			boxShadow: "rgba(0,0,0,.1) 0 2px 4px,rgba(26,26,29,.08) 0 5px 12px",
		},
		"&:active": {
			boxShadow: "rgba(0,0,0,.1) 0 2px 4px,rgba(26,26,29,.08) 0 5px 12px",
		},
		"borderRadius": "100px",
	},
	searchButton: {
		fontWeight: 700,
		width: "100%",
		padding: "8px 24px",
	},
	label: {
		justifyContent: "flex-start",
	},
	input: {
		width: "100%",
		fontWeight: 700,
		//flex: "1 1 0%",
	},
	inputService: {
		fontWeight: 600,
		padding: "14px 0 !important",
	},
	inputZip: {
		fontWeight: 600,
		flex: "0 0 130px",
		[theme.breakpoints.down("xs")]: {
			flex: "0 0 100px",
		},
	},
	iconButton: {
		"color": "#fff",
		"backgroundColor": theme.palette.primary.main,
		"&:hover, &.Mui-focusVisible": { backgroundColor: "rgb(18, 80, 162)" },
	},
	inputZipIcon: {
		padding: 5,
	},
	iconZip: {
		color: "rgba(0, 0, 0, 0.40) !important",
	},
	divider: {
		height: 28,
		margin: 4,
	},
	closeButton: {
		//position: "absolute"
	},
	mobileInput: {
		marginBottom: 12,
	},
	mobileButton: {
		height: 55,
		marginTop: 8,
		marginBottom: 24,
	},
});
