import { green, amber, grey } from "@material-ui/core/colors";

export default (theme) => ({
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	info: {
		backgroundColor: theme.palette.primary.dark,
	},
	processing: {
		color: grey[50],
	},
	warning: {
		backgroundColor: amber[700],
	},
	message: {
		display: "flex",
		fontSize: "1em",
	},
});
