import React from "react";
import PropTypes from "prop-types";
import SSR from "@components/Global/SSR";

const SchemaOrg = ({ schema }) => {
	return (
		<SSR>
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html: JSON.stringify(schema).replace(/\//g, "\\/"),
				}}
			/>
		</SSR>
	);
};

SchemaOrg.propTypes = {
	schema: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default SchemaOrg;
