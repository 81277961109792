import { observable } from "mobx";
import required from "@libs/required";

class SearchBar {
	service;
	@observable zipcode;

	constructor(rootStore, staticContext) {
		this.rootStore = rootStore;
		this.zipcode = (staticContext.app.location || {}).zipcode;
	}

	get textFieldStore() {
		const { textFieldStore } = this.rootStore.stores;
		return textFieldStore;
	}

	updateService(service) {
		this.service = service;
	}

	updateZipcode(val) {
		this.zipcode = val;
	}

	redirect(groupId = "search-bar") {
		if (!this.service) return;
		const prefix = this.service.search_to_map ? "search-map" : "search";
		if (this.service.midtail)
			window.location.href = `/${prefix}?midtail=${this.service.key}&zipcode=${
				this.zipcode || ""
			}`;
		else
			window.location.href = `/${prefix}?service=${this.service.key}&zipcode=${
				this.zipcode || ""
			}`;
	}
}

export default SearchBar;
