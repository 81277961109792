import required from "@libs/required";

class AlertStore {

    stores = [];

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    get infoStores() {
        return this.stores.filter(store => store.variant === "info");
    }

    get successStores() {
        return this.stores.filter(store => store.variant === "success");
    }

    get errorStores() {
        return this.stores.filter(store => store.variant === "error");
    }

    get warningStores() {
        return this.stores.filter(store => store.variant === "warning");
    }

    init(store = required`store`) {
        this.stores.push(store);
    }

    remove(store) {
        this.stores = this.stores.filter(_store => _store._id !== store._id);
    }
}

export default AlertStore;