import InstanceFactoryStoreManager from "@src/stores/InstanceFactoryStoreManager";
import required from "@libs/required";

import SearchBar from "./SearchBar";

class SearchBarStore extends InstanceFactoryStoreManager {
	constructor(rootStore, staticContext) {
		super(rootStore, staticContext);
	}

	init(id = required`id`) {
		super.init(id, SearchBar);
	}

	findInstanceById(id = required`id`) {
		return super.findInstanceById(id);
	}
}

export default SearchBarStore;
