import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import clsx from "clsx";

import useStyles from "@hooks/useStyles";

import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import MaterialSnackBar from "@material-ui/core/Snackbar";
import MaterialSnackBarContent from "@material-ui/core/SnackbarContent";
import CloseIcon from "@material-ui/icons/CloseOutlined";

const SnackBarContent = forwardRef(
	({ className, message, onClose, processing, variant, ...other }, ref) => {
		const classes = useStyles("alert");

		return (
			<MaterialSnackBarContent
				ref={ref}
				className={clsx(classes[variant], className)}
				message={
					<span className={classes.message}>
						{processing && (
							<React.Fragment>
								<CircularProgress className={classes.processing} size={25} />
								&nbsp;&nbsp;&nbsp;&nbsp;
							</React.Fragment>
						)}
						<label>{message}</label>
					</span>
				}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						size={"small"}
						onClick={onClose}
					>
						<CloseIcon />
					</IconButton>,
				]}
				{...other}
			/>
		);
	}
);

SnackBarContent.propTypes = {
	processing: PropTypes.bool,
	className: PropTypes.string,
	message: PropTypes.node,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
};

function TransitionDown(props) {
	return <Slide {...props} direction="down" />;
}

const SnackBar = observer(({ snackbarStore, onClose, ...props }) => {
	function handleClose(event, reason) {
		if (reason === "clickaway") {
			return;
		}
		if (onClose) onClose();
		else snackbarStore.show = false;
	}

	return (
		<MaterialSnackBar
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			TransitionComponent={TransitionDown}
			{...props}
			open={snackbarStore.show}
			onClose={handleClose}
		>
			<SnackBarContent
				onClose={handleClose}
				processing={snackbarStore.processing || false}
				variant={snackbarStore.variant}
				message={snackbarStore.message}
			/>
		</MaterialSnackBar>
	);
});

SnackBar.propTypes = {
	snackbarStore: PropTypes.object.isRequired,
	autoHideDuration: PropTypes.number,
};

export default SnackBar;
