import React, { useEffect } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { compose } from "recompose";

import useStores from "@hooks/useStores";

import withStores from "@framework/src/libs/withStores";
import withStyles from "@framework/src/libs/withStyles";

import SnackBar from "./Shared/SnackBar";

import { styles, stores } from "./.config";

const ErrorSnackbar = ({
	store,
	transitionDuration,
	autoHideDuration,
	...props
}) => (
	<SnackBar
		transitionDuration={transitionDuration}
		autoHideDuration={autoHideDuration}
		snackbarStore={store}
		onClose={() => store.onClose()}
		{...props}
	/>
);

ErrorSnackbar.defaultProps = {
	transitionDuration: { enter: 300, exit: 300 },
};

ErrorSnackbar.propTypes = {
	store: PropTypes.object.isRequired,
	autoHideDuration: PropTypes.number,
	transitionDuration: PropTypes.shape({
		enter: PropTypes.number,
		exit: PropTypes.number,
	}),
};

const ErrorBanner = ({ message }) => (
	<React.Fragment>
		{message && (
			<React.Fragment>
				<div className="alert alert-danger" role="alert">
					<i className="fa fa-info-circle" /> {message}
				</div>
			</React.Fragment>
		)}
	</React.Fragment>
);

ErrorBanner.propTypes = {
	message: PropTypes.string.isRequired,
};

const Error = compose(
	withStyles(styles),
	withStores(stores),
	observer
)(({ store, banner, ...props }) => {
	const { alertStore } = useStores();
	// if (store.show) {
	// 	console.error(store.stack);
	// }
	useEffect(() => {
		alertStore.init(store);
		return () => alertStore.remove(store);
	}, []);

	if (banner === true) {
		return <ErrorBanner {...props} message={store.message} />;
	}

	return <ErrorSnackbar {...props} store={store} />;
});

Error.propTypes = {
	store: PropTypes.object.isRequired,
	banner: PropTypes.bool,
	transitionDuration: PropTypes.shape({
		enter: PropTypes.number,
		exit: PropTypes.number,
	}),
};

export default Error;
