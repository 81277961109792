import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";

import withStyles from "@framework/src/libs/withStyles";
import withStores from "@framework/src/libs/withStores";
import { observer } from "mobx-react";
import { useTheme } from "@material-ui/core/styles";

import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";

import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import AutoComplete from "@components/Global/Custom/Autocomplete";

import { ZipcodeTextField } from "@components/Global/Custom/TextField";

import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";

import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStaticContext from "@hooks/useStaticContext";
//import Button from "@material-ui/core/Button";

import { styles, stores } from "./.config";

const SearchBar = compose(
	withStyles(styles),
	withStores(stores),
	observer
)(({ id, searchPlaceHolder, searchOptions }) => {
	const classes = useStyles("SearchBar");
	const { app } = useStaticContext();
	const { searchBarStore: store, homeStore } = useStores();
	const instance = store.findInstanceById(id);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	const searchOnClickHandler = (e) => {
		e.preventDefault();
		if (!app.location?.zipcode && isMobile) {
			homeStore.displaySearchDrawer(true);
			return null;
		}
		instance.redirect();
	};
	const zipcodeOnChangeHandler = (e) => instance.updateZipcode(e.target.value);
	const serviceOnChangeHandler = (service) => {
		if (homeStore) homeStore.updateService(service);
		instance.updateService(service);
	};

	const serviceOnSelectHandler = (service) => {
		instance.updateService(service);
		if (instance.zipcode) instance.redirect();
	};

	useEffect(() => {
		store.init(id);
	}, []);

	return (
		<div id={id}>
			<Box component={"div"} width={"100%"}>
				<Paper
					component="form"
					classes={{ root: classes.root }}
					className={classes.searchBorder}
					autoComplete="on"
				>
					<AutoComplete
						useDefaultStartAdornment={false}
						autoHighlight
						id={"searchBarInput"}
						className={classes.input}
						TextFieldProps={{
							disableErrorText: true,
							groupId: "search-bar",
							disableOnBlurValidation: true,
							placeholder: searchPlaceHolder,
							name: "service",
							autoFocus: true,
							InputProps: {
								disableUnderline: true,
							},
							inputProps: {
								ariaLabel: searchPlaceHolder,
								className: classes.inputService,
							},
						}}
						onSelect={serviceOnSelectHandler}
						onChange={serviceOnChangeHandler}
						searchOptions={searchOptions}
					/>
					<Box display={{ xs: "none", sm: "block" }}>
						<Divider className={classes.divider} orientation="vertical" />
					</Box>

					<Box display={{ xs: "none", sm: "block" }}>
						<ZipcodeTextField
							groupId={"search-bar"}
							disableOnBlurValidation
							disableErrorText
							variant={"standard"}
							id="main-search-zip"
							name={"zip_code"}
							useDefaultStartAdornment={false}
							value={(instance || { zipcode: "" }).zipcode || ""}
							onChange={zipcodeOnChangeHandler}
							InputProps={{
								style: {
									fontWeight: "600",
								},
								classes: { root: classes.iconZip },
								startAdornment: (
									<Box
										className={classes.inputZipIcon}
										display={"flex"}
										alignItems={"center"}
									>
										<RoomOutlinedIcon />
									</Box>
								),
							}}
							inputProps={{
								className: classes.inputZip,
							}}
						/>
					</Box>
					<IconButton
						color="primary"
						aria-label="Search"
						name={"submit"}
						id={"submit"}
						className={classes.iconButton}
						type={"submit"}
						onClick={searchOnClickHandler}
					>
						<SearchIcon />
					</IconButton>
				</Paper>
			</Box>

			{/* <Box display={{ xs: "block", sm: "none" }}>
				<div className={classes.mobileInput}>
					<AutoComplete
						useDefaultStartAdornment={false}
						autoHighlight
						id={"searchBarInput"}
						className={classes.input}
						TextFieldProps={{
							disableErrorText: true,
							groupId: "search-bar",
							disableOnBlurValidation: true,
							placeholder: searchPlaceHolder,
							name: "service",
							autoFocus: false,
							variant: "outlined",
							InputProps: {
								disableUnderline: true,
							},
							inputProps: {
								ariaLabel: searchPlaceHolder,
								className: classes.inputService,
							},
						}}
						onSelect={serviceOnSelectHandler}
						onChange={serviceOnChangeHandler}
						searchOptions={searchOptions}
					/>
				</div>
				<div className={classes.mobileInput}>
					<ZipcodeTextField
						groupId={"search-bar"}
						disableOnBlurValidation
						disableErrorText
						fullWidth
						variant={"outlined"}
						id="main-search-zip"
						name={"zip_code"}
						useDefaultStartAdornment={false}
						value={(instance || { zipcode: "" }).zipcode}
						onChange={zipcodeOnChangeHandler}
						InputProps={{
							style: {
								fontWeight: "600",
							},
							classes: { root: classes.iconZip },
							startAdornment: (
								<Box
									className={classes.inputZipIcon}
									display={"flex"}
									alignItems={"center"}
								>
									<RoomOutlinedIcon />
								</Box>
							),
						}}
						inputProps={{
							className: classes.inputZip,
						}}
					/>
				</div>

				<Button
					fullWidth
					disableElevation
					size="large"
					color="primary"
					aria-label="Search"
					variant="contained"
					name={"submit"}
					id={"submit"}
					className={classes.mobileButton}
					type={"submit"}
					onClick={searchOnClickHandler}
					startIcon={<SearchIcon />}
				>
					Search
				</Button>
			</Box> */}
		</div>
	);
});

SearchBar.defaultProps = {
	id: "search-bar",
};

SearchBar.propTypes = {
	id: PropTypes.string.isRequired,
	searchPlaceHolder: PropTypes.string,
	searchOptions: PropTypes.object,
};

export default SearchBar;
